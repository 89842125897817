import { Component, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ScreenService } from '@core/services/screen/screen.service';
import { merge, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-hour-filter',
  templateUrl: './hour-filter.component.html',
  styleUrls: ['./hour-filter.component.scss'],
})
export class HourFilterComponent implements OnInit {
  @Input() screenFilterForm: FormGroup;
  @Input() shouldOnlyDisplayBreakCodeOption = false;

  hourForm: FormGroup;
  showDropDown = false;

  public displayHour: { id: number; display: string; name: string }[] = [
    { id: 1, display: 'Intitulé', name: 'herecran' },
    { id: 2, display: 'Heure ', name: 'herreelle' },
  ];

  readonly filterAllTimeSlot: { name: string; ischeck: boolean } = { name: 'Toutes les plages horaires', ischeck: true };

  constructor(private eRef: ElementRef, private fb: FormBuilder, private screenService: ScreenService) {}

  private componentDestroyed$: Subject<void> = new Subject();

  ngOnInit(): void {
    this.filterDisplayOptions();
    this.initForm();
    this.initValueChange();
    this.setDefaultValues();
  }

  filterDisplayOptions() {
    if (this.shouldOnlyDisplayBreakCodeOption) {
      this.displayHour = this.displayHour.filter(option => option.id === 1);
    }
  }

  initForm() {
    this.hourForm = this.fb.group({
      startHour: ['00:00', [Validators.required]],
      endHour: ['27:99', [Validators.required]],
    });
  }

  initValueChange() {
    merge(this.hourForm.get('startHour').valueChanges, this.hourForm.get('endHour').valueChanges)
      .pipe(debounceTime(100), takeUntil(this.componentDestroyed$))
      .subscribe(
        () => {
          this.filterAllTimeSlot.ischeck = false;
          this.screenFilterForm.get('startHour').setValue(this.hourForm.value.startHour);
          this.screenFilterForm.get('endHour').setValue(this.hourForm.value.endHour);
        },
        (error: Error) => {
          console.error(error);
        }
      );

    // When create new screen, we display new screen hour in form
    this.screenService.updateScreenHour$.pipe(takeUntil(this.componentDestroyed$)).subscribe(
      updateScreenHour => {
        this.filterAllTimeSlot.ischeck = false;
        this.hourForm.get('startHour').setValue(updateScreenHour, { emitEvent: false }),
          this.hourForm.get('endHour').setValue(updateScreenHour, { emitEvent: false });
      },
      (error: Error) => {
        console.error(error);
      }
    );
  }

  setDefaultValues() {
    this.screenFilterForm.get('hourType').setValue(this.displayHour[0].name, { emitEvent: false });
    this.screenFilterForm.get('startHour').setValue(this.hourForm.value.startHour);
    this.screenFilterForm.get('endHour').setValue(this.hourForm.value.endHour);
  }

  onShowDropDownButtonClick() {
    this.showDropDown = !this.showDropDown;
  }

  selectedDisplayHourType(value) {
    this.screenFilterForm.get('hourType').setValue(value.name, { emitEvent: false });
  }

  onSelectAllTimeSlotChange() {
    this.filterAllTimeSlot.ischeck = !this.filterAllTimeSlot.ischeck;

    // If the checkbox is checked we set default value to hourForm and screenFilterForm
    this.hourForm.get('startHour').setValue('00:00', { emitEvent: false }),
      this.hourForm.get('endHour').setValue('27:99', { emitEvent: false }),
      this.screenFilterForm.get('startHour').setValue(this.hourForm.value.startHour);
    this.screenFilterForm.get('endHour').setValue(this.hourForm.value.endHour);
  }

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement) {
    const clickedInside = this.eRef.nativeElement.contains(targetElement);

    if (!clickedInside) {
      this.showDropDown = !!this.eRef.nativeElement.contains(targetElement);
    }
  }
}
