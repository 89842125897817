import * as moment from 'moment';

export interface JsonScreen {
  brutPrice: number;
  netPrice: number;
  grp: number;
  available: string;
  channelId: string;
  screenHour: string;
  cgrpBrut30s: number;
  cgrpNet30s: number;
  cgrpBrut20s: number;
  cgrpNet20s: number;
  tauxTarif: number;
  tauxTarif30s: number;
  tauxTarif20s: number;
  productPresence: string;
  programBefore: string;
  programAfter: string;
  isRegional: string;
  realScreenDay: string;
}

export class Screen {
  brutPrice: number;
  netPrice: number;
  grp: number;
  cgrpBrut30s: number;
  cgrpNet30s: number;
  cgrpBrut20s: number;
  cgrpNet20s: number;
  tauxTarif: number;
  tauxTarif30s: number;
  tauxTarif20s: number;
  programBefore: string;
  programAfter: string;
  screenHour: string;
  available?: boolean;
  productPresence?: boolean;
  alreadyInPlan?: boolean;
  isRegional: string;
  realScreenDay: string;

  constructor(jsonScreen: JsonScreen) {
    if (jsonScreen.brutPrice) {
      this.brutPrice = jsonScreen.brutPrice;
    }

    if (jsonScreen.netPrice) {
      this.netPrice = jsonScreen.netPrice;
    }

    if (jsonScreen.grp) {
      this.grp = jsonScreen.grp;
    }

    if (jsonScreen.cgrpBrut30s) {
      this.cgrpBrut30s = jsonScreen.cgrpBrut30s;
    }

    if (jsonScreen.cgrpBrut20s) {
      this.cgrpBrut20s = jsonScreen.cgrpBrut20s;
    }

    if (jsonScreen.cgrpNet30s) {
      this.cgrpNet30s = jsonScreen.cgrpNet30s;
    }

    if (jsonScreen.cgrpNet20s) {
      this.cgrpNet20s = jsonScreen.cgrpNet20s;
    }

    if (jsonScreen.tauxTarif) {
      this.tauxTarif = jsonScreen.tauxTarif;
    }

    if (jsonScreen.tauxTarif30s) {
      this.tauxTarif30s = jsonScreen.tauxTarif30s;
    }

    if (jsonScreen.tauxTarif20s) {
      this.tauxTarif20s = jsonScreen.tauxTarif20s;
    }

    if (jsonScreen.programBefore) {
      this.programBefore = jsonScreen.programBefore;
    }

    if (jsonScreen.programAfter) {
      this.programAfter = jsonScreen.programAfter;
    }

    if (jsonScreen.screenHour) {
      this.screenHour = jsonScreen.screenHour;
    }

    if (jsonScreen.available === '1') {
      this.available = true;
    }

    if (+jsonScreen.productPresence >= 1) {
      this.productPresence = true;
    }

    if (jsonScreen.isRegional) {
      this.isRegional = jsonScreen.isRegional;
    }

    if (jsonScreen.realScreenDay) {
      this.realScreenDay = jsonScreen.realScreenDay;
    }
  }
}

export interface JsonTvScreen {
  screenChannel: string;
  screenRegion: string;
  screenTemregion: string;
  channelGroup: string;
  screenDate: string;
  screenHour: string;
  screenTheoHour: string;
  screenHourDiff: string;
  screenStatusId: string;
  screenStatus: string;
  screenSlotAvailable: string;
  screenSlotMax: string;
  screenSlotId: string;
  screenTemconduct: string;
  screenHQ: string;
  screenDurAvailability: string;
  screenDurSold: string;
  screenDurMax: number;
  screenDurOuv: number;
  screenType: string;
  screenTypeLib: string;
  screenComment: string;
  programBefore: string;
  programAfter: string;
  secteur_1: string;
  secteur_2: string;
  secteur_3: string;
  tarif_20h15: string;
  tarif_unique: string;
  captif: string;
  achat_unitaire: string;
  international: string;
  screenCaNet: string;
  screenCaBrut: string;
  screenForcable: string;
  isReserve: string;
}

export class TvScreen {
  screenChannel: string;
  screenRegion: string;
  screenTemregion: string;
  channelGroup: string;
  screenDate: string;
  screenHour: string;
  screenTheoHour: string;
  screenHourDiff: string;
  screenStatusId: string;
  screenStatus: string;
  screenSlotAvailable: string;
  screenSlotMax: string;
  screenHQ: string;
  screenDurAvailability: string;
  screenDurSold: string;
  screenDurMax: number;
  screenDurOuv: number;
  screenSlotId: string;
  screenTemconduct: string;
  screenType: string;
  screenTypeLib: string;
  screenComment: string;
  programBefore: string;
  programAfter: string;
  secteur_1: string;
  secteur_2: string;
  secteur_3: string;
  tarif_20h15: string;
  tarif_unique: string;
  captif: string;
  achat_unitaire: string;
  international: string;
  screenCaNet: string;
  screenCaBrut: string;
  screenForcable: string;
  isReserve: string;

  constructor(jsonTvScreen: JsonTvScreen) {
    if (jsonTvScreen.screenChannel) {
      this.screenChannel = jsonTvScreen.screenChannel;
    }

    if (jsonTvScreen.screenRegion) {
      this.screenRegion = jsonTvScreen.screenRegion;
    }

    if (jsonTvScreen.screenTemregion) {
      this.screenTemregion = jsonTvScreen.screenTemregion;
    }

    if (jsonTvScreen.channelGroup) {
      this.channelGroup = jsonTvScreen.channelGroup;
    }

    if (jsonTvScreen.screenDate) {
      this.screenDate = jsonTvScreen.screenDate;
    }

    if (jsonTvScreen.screenHourDiff) {
      this.screenHourDiff = jsonTvScreen.screenHourDiff;
    }

    if (jsonTvScreen.screenHour) {
      this.screenHour = jsonTvScreen.screenHour;
    }

    if (jsonTvScreen.screenDurMax) {
      this.screenDurMax = jsonTvScreen.screenDurMax;
    }

    if (jsonTvScreen.screenDurOuv) {
      this.screenDurOuv = jsonTvScreen.screenDurOuv;
    }

    if (jsonTvScreen.screenSlotId) {
      this.screenSlotId = jsonTvScreen.screenSlotId;
    }

    if (jsonTvScreen.screenTemconduct) {
      this.screenTemconduct = jsonTvScreen.screenTemconduct;
    }

    if (jsonTvScreen.screenDurAvailability) {
      this.screenDurAvailability = jsonTvScreen.screenDurAvailability;
    }

    if (jsonTvScreen.screenStatusId) {
      this.screenStatusId = jsonTvScreen.screenStatusId;
    }

    if (jsonTvScreen.screenStatus) {
      this.screenStatus = jsonTvScreen.screenStatus;
    }

    if (jsonTvScreen.screenTheoHour) {
      this.screenTheoHour = jsonTvScreen.screenTheoHour;
    }

    if (jsonTvScreen.screenType) {
      this.screenType = jsonTvScreen.screenType;
    }

    if (jsonTvScreen.screenTypeLib) {
      this.screenTypeLib = jsonTvScreen.screenTypeLib;
    }

    if (jsonTvScreen.programBefore) {
      this.programBefore = jsonTvScreen.programBefore;
    }

    if (jsonTvScreen.programAfter) {
      this.programAfter = jsonTvScreen.programAfter;
    }

    if (jsonTvScreen.secteur_1) {
      this.secteur_1 = jsonTvScreen.secteur_1;
    }

    if (jsonTvScreen.secteur_2) {
      this.secteur_2 = jsonTvScreen.secteur_2;
    }

    if (jsonTvScreen.secteur_3) {
      this.secteur_3 = jsonTvScreen.secteur_3;
    }

    if (jsonTvScreen.tarif_20h15) {
      this.tarif_20h15 = jsonTvScreen.tarif_20h15;
    }

    if (jsonTvScreen.tarif_unique) {
      this.tarif_unique = jsonTvScreen.tarif_unique;
    }

    if (jsonTvScreen.screenComment) {
      this.screenComment = jsonTvScreen.screenComment;
    }

    if (jsonTvScreen.screenSlotAvailable) {
      this.screenSlotAvailable = jsonTvScreen.screenSlotAvailable;
    }

    if (jsonTvScreen.screenSlotMax) {
      this.screenSlotMax = jsonTvScreen.screenSlotMax;
    }

    if (jsonTvScreen.screenHQ) {
      this.screenHQ = jsonTvScreen.screenHQ;
    }

    if (jsonTvScreen.captif) {
      this.captif = jsonTvScreen.captif;
    }

    if (jsonTvScreen.achat_unitaire) {
      this.achat_unitaire = jsonTvScreen.achat_unitaire;
    }

    if (jsonTvScreen.international) {
      this.international = jsonTvScreen.international;
    }

    if (jsonTvScreen.screenCaNet) {
      this.screenCaNet = jsonTvScreen.screenCaNet;
    }

    if (jsonTvScreen.screenCaBrut) {
      this.screenCaBrut = jsonTvScreen.screenCaBrut;
    }

    if (jsonTvScreen.screenForcable) {
      this.screenForcable = jsonTvScreen.screenForcable;
    }

    if (jsonTvScreen.isReserve) {
      this.isReserve = jsonTvScreen.isReserve;
    }
  }
}

export interface JsonTvScreenSpot {
  dateEcran: string;
  productName: string;
  productId: number;
  productType: string;
  advertiser: string;
  campaignNum: number;
  durSpot: number;
  EMPLACECODE: string;
  caInit: number;
  caNet: Number;
  priceSector: string;
  ABATT: string;
  utPlanning: string;
  isReserve: string;
}

export class TvScreenSpot {
  dateEcran: string;
  productName: string;
  productId: number;
  productType: string;
  advertiser: string;
  campaignNum: number;
  durSpot: number;
  emplaceCode: string;
  caInit: number;
  caNet: Number;
  priceSector: string;
  abatt: string;
  utPlanning: string;
  isReserve: string;

  constructor(jsonTvScreenSpot: JsonTvScreenSpot) {
    this.dateEcran = jsonTvScreenSpot.dateEcran;
    this.productName = jsonTvScreenSpot.productName;
    this.productId = jsonTvScreenSpot.productId;
    this.productType = jsonTvScreenSpot.productType;
    this.advertiser = jsonTvScreenSpot.advertiser;
    this.campaignNum = jsonTvScreenSpot.campaignNum;
    this.durSpot = jsonTvScreenSpot.durSpot;
    this.emplaceCode = jsonTvScreenSpot.EMPLACECODE;
    this.caInit = jsonTvScreenSpot.caInit;
    this.caNet = jsonTvScreenSpot.caNet;
    this.priceSector = jsonTvScreenSpot.priceSector;
    this.abatt = jsonTvScreenSpot.ABATT;
    this.utPlanning = jsonTvScreenSpot.utPlanning;

    if (jsonTvScreenSpot.dateEcran) {
      this.dateEcran = jsonTvScreenSpot.dateEcran;
    }
    if (jsonTvScreenSpot.productName) {
      this.productName = jsonTvScreenSpot.productName;
    }
    if (jsonTvScreenSpot.productType) {
      this.productType = jsonTvScreenSpot.productType;
    }
    if (jsonTvScreenSpot.productId) {
      this.productId = jsonTvScreenSpot.productId;
    }
    if (jsonTvScreenSpot.campaignNum) {
      this.campaignNum = jsonTvScreenSpot.campaignNum;
    }
    if (jsonTvScreenSpot.durSpot) {
      this.durSpot = jsonTvScreenSpot.durSpot;
    }
    if (jsonTvScreenSpot.EMPLACECODE) {
      this.emplaceCode = jsonTvScreenSpot.EMPLACECODE;
    }
    if (jsonTvScreenSpot.caInit) {
      this.caInit = jsonTvScreenSpot.caInit;
    }
    if (jsonTvScreenSpot.caNet) {
      this.caNet = jsonTvScreenSpot.caNet;
    }
    if (jsonTvScreenSpot.ABATT) {
      this.abatt = jsonTvScreenSpot.ABATT;
    }
    if (jsonTvScreenSpot.utPlanning) {
      this.utPlanning = jsonTvScreenSpot.utPlanning;
    }
    if (jsonTvScreenSpot.advertiser) {
      this.advertiser = jsonTvScreenSpot.advertiser;
    }
    if (jsonTvScreenSpot.priceSector) {
      this.priceSector = jsonTvScreenSpot.priceSector;
    }
    if (jsonTvScreenSpot.isReserve) {
      this.isReserve = jsonTvScreenSpot.isReserve;
    }
  }
}

export interface JsonTvScreenHistory {
  dateMvt: number;
  utName: number;
  utCode: string;
  commentMvt: number;
  valueBefore: Number;
  valueAfter: string;
  hourBefore: string;
  hourAfter: string;
  screenStatusBefore: string;
  screenStatusAfter: string;
}

export class TvScreenHistory {
  dateMvt: number;
  utName: number;
  utCode: string;
  commentMvt: number;
  valueBefore: Number;
  valueAfter: string;
  hourBefore: string;
  hourAfter: string;
  screenStatusBefore: string;
  screenStatusAfter: string;

  constructor(jsonTvScreenHistory: JsonTvScreenHistory) {
    if (jsonTvScreenHistory.dateMvt) {
      this.dateMvt = jsonTvScreenHistory.dateMvt;
    }
    if (jsonTvScreenHistory.utName) {
      this.utName = jsonTvScreenHistory.utName;
    }
    if (jsonTvScreenHistory.utCode) {
      this.utCode = jsonTvScreenHistory.utCode;
    }
    if (jsonTvScreenHistory.commentMvt) {
      this.commentMvt = jsonTvScreenHistory.commentMvt;
    }
    if (jsonTvScreenHistory.valueBefore) {
      this.valueBefore = jsonTvScreenHistory.valueBefore;
    }
    if (jsonTvScreenHistory.valueAfter) {
      this.valueAfter = jsonTvScreenHistory.valueAfter;
    }
    if (jsonTvScreenHistory.hourBefore) {
      this.hourBefore = jsonTvScreenHistory.hourBefore;
    }
    if (jsonTvScreenHistory.hourAfter) {
      this.hourAfter = jsonTvScreenHistory.hourAfter;
    }
    if (jsonTvScreenHistory.screenStatusBefore) {
      this.screenStatusBefore = jsonTvScreenHistory.screenStatusBefore;
    }
    if (jsonTvScreenHistory.screenStatusAfter) {
      this.screenStatusAfter = jsonTvScreenHistory.screenStatusAfter;
    }
  }
}

export interface JsonScreenType {
  libevt: string;
  codevt: number;
  children?: [];
}

export class ScreenType {
  libevt: string;
  codevt: number;
  children: [];

  constructor(jsonScreenType: JsonScreenType) {
    this.libevt = jsonScreenType.libevt;
    this.codevt = jsonScreenType.codevt;

    if (jsonScreenType.children) {
      this.children = jsonScreenType.children;
    }
  }
}

export interface JsonScreenStatus {
  libtype: string;
  codtype: number;
}

export class ScreenStatus {
  libtype: string;
  codtype: number;

  constructor(jsonScreenStatus: JsonScreenStatus) {
    this.libtype = jsonScreenStatus.libtype;
    this.codtype = jsonScreenStatus.codtype;
  }
}

export enum screenStatus {
  All = 'Tous',
  Opened = 'Ouverts',
  Closed = 'Fermés',
  Inactivated = 'Inactifs',
  Incident = 'Incident diff.',
  Errors = 'Erreurs',
}

export interface JsonChannelLastOpenedDate {
  channel_id: string;
  last_date: string;
}

export class ChannelLastOpenedDate {
  channelId: string;
  lastOpenDate: string;

  constructor(jsonLastOpenedScreenDateByChannel: JsonChannelLastOpenedDate) {
    if (jsonLastOpenedScreenDateByChannel?.channel_id) {
      this.channelId = jsonLastOpenedScreenDateByChannel.channel_id;
    }

    if (jsonLastOpenedScreenDateByChannel?.last_date) {
      this.lastOpenDate = jsonLastOpenedScreenDateByChannel.last_date;
    }
  }
}

export interface JsonCampaignAbattement {
  campaignId: string;
  abattement: string;
  startDate: string;
  endDate: string;
}

export class CampaignAbattement {
  campaignId: string;
  abattement: string;
  startDate: string;
  endDate: string;

  constructor(jsonCampaignAbattement: JsonCampaignAbattement) {
    if (jsonCampaignAbattement?.campaignId) {
      this.campaignId = jsonCampaignAbattement.campaignId;
    }

    if (jsonCampaignAbattement?.abattement) {
      this.abattement = jsonCampaignAbattement.abattement;
    }

    if (jsonCampaignAbattement?.startDate) {
      this.startDate = moment(jsonCampaignAbattement.startDate, 'YYYY-MM-DD').format('DD/MM/YYYY');
    }

    if (jsonCampaignAbattement?.endDate) {
      this.endDate = moment(jsonCampaignAbattement.endDate, 'YYYY-MM-DD').format('DD/MM/YYYY');
    }
  }
}

export interface CalculateTotalSpots {
  totalBudgetNet: number;
  totalGrp: number;
  totalNet30: number;
  totalCgrpNet30: number;
  totalNet20: number;
  totalCgrpNet20: number;
  totalSpots: number;
  couverture: number | null;
}
